<template>
    <div class="mainWrapper pb-0">
    <section class="blockElement space ourCompany homeBanner bg-white">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-6 text-center">
                    <h1 class="bold mb-md-4">{{$t('copyTrading.copyText1')}}</h1>
                    <p class="mb-md-4">{{$t('copyTrading.copyText2')}}</p>
                    <div class="dualButton text-center mt-5">
                        <a :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn me-3">{{$t('copyTrading.copyText3')}}</a>
                        <a :href="static_vars.domainURL+'register/'" class="button zulu_btn border-button rounded">{{$t('copyTrading.copyText4')}}</a>
                    </div>
                </div>
                <div class="col-12 col-lg-10 text-center">
                    <video style="width: 100%; pointer-events: none;" width="100%" height="420" autoplay playsinline muted loop preload="auto" class="bannerVideo light">
                        <source src="/assets/video/Homepage-herobanner-v4.mp4" :type="$t('copyTrading.copyText5')" />
                        <source src="/assets/video/Homepage-herobanner-v4.ogg" :type="$t('copyTrading.copyText6')" />
                    </video>
                    <video style="width: 100%; pointer-events: none;" width="100%" height="420" autoplay playsinline muted loop preload="auto" class="bannerVideo dark d-none">
                        <source src="/assets/video/Homepage-herobanner-dark.mp4" :type="$t('copyTrading.copyText5')" />
                        <source src="/assets/video/Homepage-herobanner-dark.ogg" :type="$t('copyTrading.copyText6')" />
                    </video>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement space pt-0 whoWeAre">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-9 text-center">
                    <div class="boxed customTop">
                        <h2 class="mb-md-4">{{$t('copyTrading.copyText7')}} </h2>
                        <p>{{$t('copyTrading.copyText8')}} </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="blockElement mobCenter space">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="mb-md-4 pb-4 text-center">{{$t('copyTrading.copyText9')}}</h2>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card-body">
                        <div class="d-block mb-4">
                            <v-lazy-image class="d-block h-70" src="/assets/images/learn.svg" :alt="$t('copyTrading.copyText10')" />
                        </div>
                        <div>
                            <h4 class="bold">{{$t('copyTrading.copyText10')}}</h4>
                            <p>{{$t('copyTrading.copyText11')}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card-body">
                        <div class="d-block mb-4"><v-lazy-image class="d-block h-70" src="/assets/images/diversion.svg" :alt="$t('copyTrading.copyText12')" /></div>

                        <div>
                            <h4 class="bold">{{$t('copyTrading.copyText12')}}</h4>

                            <p> {{$t('copyTrading.copyText13')}} </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card-body">
                        <div class="d-block mb-4">
                            <v-lazy-image class="d-block h-70" src="/assets/images/Protect.svg" :alt="$t('copyTrading.copyText14')" />
                        </div>
                        <div>
                            <h4 class="bold">{{$t('copyTrading.copyText14')}}</h4>
                            <p>{{$t('copyTrading.copyText15')}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card-body">
                        <div class="d-block mb-4"><v-lazy-image class="d-block h-70" src="/assets/images/limited-market-knowledge.svg" :alt="$t('copyTrading.copyText16')" /></div>

                        <div>
                            <h4 class="bold">{{$t('copyTrading.copyText16')}}</h4>

                            <p> {{$t('copyTrading.copyText17')}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card-body">
                        <div class="d-block mb-4"><v-lazy-image class="d-block h-70" src="/assets/images/Pasive-investment.svg" :alt="$t('copyTrading.copyText18')" /></div>
                        <div>
                            <h4 class="bold">{{$t('copyTrading.copyText18')}}</h4>
                            <p> {{$t('copyTrading.copyText19')}} </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card-body">
                        <div class="d-block mb-4">
                            <v-lazy-image class="d-block h-70" src="/assets/images/Save-time.svg" :alt="$t('copyTrading.copyText20')" />
                        </div>
                        <div>
                            <h4 class="bold">{{$t('copyTrading.copyText20')}}</h4>
                            <p>{{$t('copyTrading.copyText21')}}</p>
                        </div>
                    </div>
                </div>
                <div class="dualButton text-center mt-5">
                    <a :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn me-3">{{$t('copyTrading.copyText3')}}</a>
                    <a :href="static_vars.domainURL+'register/'" class="button zulu_btn border-button rounded">{{$t('copyTrading.copyText4')}}</a>
                </div>
            </div>
        </div>
    </section>

    <section class="blockElement space">
        <div class="container">
            <div class="row">
                <div class="col-12 mb-3 mb-md-4 text-center">
                    <h2>{{$t('copyTrading.copyText22')}}</h2>
                </div>
                <div class="responsive-table h-auto wl-table p-0 spaceTableEqual">
                    <table class="table table-bordered cellSpacing text-start mb-0">
                        <thead>
                            <tr>
                                <th class="text-start">{{$t('copyTrading.copyText23')}}</th>
                                <th>{{$t('copyTrading.copyText24')}}</th>
                                <th>{{$t('copyTrading.copyText25')}}</th>
                            </tr>
                        </thead>
                        <tr>
                            <td>{{$t('copyTrading.copyText26')}}</td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/x.svg" :alt="$t('copyTrading.copyText27')" />
                            </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('copyTrading.copyText28')" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{$t('copyTrading.copyText29')}}</td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/x.svg" :alt="$t('copyTrading.copyText27')" />
                            </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('copyTrading.copyText28')" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{$t('copyTrading.copyText30')}}</td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/x.svg" :alt="$t('copyTrading.copyText27')" />
                            </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('copyTrading.copyText28')" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{$t('copyTrading.copyText31')}}</td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('copyTrading.copyText28')" />
                            </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('copyTrading.copyText28')" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{$t('copyTrading.copyText32')}}</td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('copyTrading.copyText28')" />
                            </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('copyTrading.copyText28')" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{$t('copyTrading.copyText33')}}</td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/x.svg" :alt="$t('copyTrading.copyText27')" />
                            </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('copyTrading.copyText28')" />
                            </td>
                        </tr>

                        <tr>
                            <td>{{$t('copyTrading.copyText34')}}</td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/x.svg" :alt="$t('copyTrading.copyText27')" />
                            </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('copyTrading.copyText28')" />
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </section>

    <section class="blockElement space automated4 bg-white">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-center mb-md-4 pb-4">{{$t('copyTrading.copyText35')}}</h2>
                    <ul class="listCounter">
                        <li class="d-flex align-items-start pb-4">
                            <span class="customXpad d-flex justify-content-center">
                                <v-lazy-image class="d-block" src="/assets/images/open-account.svg" :alt="$t('copyTrading.copyText36')" :title="$t('copyTrading.copyText37')" />
                            </span>
                            <label class="d-block">
                                <h4 class="mb-2 bold">{{$t('copyTrading.copyText38')}}</h4>
                                <p>{{$t('copyTrading.copyText39')}}</p>
                            </label>
                        </li>
                        <li class="d-flex align-items-start pb-4">
                            <span class="customXpad d-flex justify-content-center">
                                <v-lazy-image class="d-block" src="/assets/images/step-2.svg" :alt="$t('copyTrading.copyText40')" :title="$t('copyTrading.copyText41')" />
                            </span>
                            <label class="d-block">
                                <h4 class="mb-2 bold">{{$t('copyTrading.copyText42')}}</h4>
                                <p> {{$t('copyTrading.copyText43')}} </p>
                            </label>
                        </li>
                        <li class="d-flex align-items-start pb-4">
                            <span class="customXpad d-flex justify-content-center">
                                <v-lazy-image class="d-block" src="/assets/images/Select-trader.svg" :alt="$t('copyTrading.copyText44')" :title="$t('copyTrading.copyText45')" />
                            </span>
                            <label class="d-block">
                                <h4 class="mb-2 bold">{{$t('copyTrading.copyText46')}}</h4>
                                <p>{{$t('copyTrading.copyText47')}}</p>
                            </label>
                        </li>
                        <li class="d-flex align-items-start pb-4">
                            <span class="customXpad d-flex justify-content-center">
                                <v-lazy-image class="d-block" src="/assets/images/Start-journey.svg" :alt="$t('copyTrading.copyText48')" :title="$t('copyTrading.copyText49')" />
                            </span>
                            <label class="d-block">
                                <h4 class="mb-2 bold">{{$t('copyTrading.copyText48')}}</h4>
                                <p>{{$t('copyTrading.copyText50')}}</p>
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="col-12">
                    <div class="dualButton d-flex align-items-center justify-content-center mt-4">
                        <a :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn large me-3 px-md-4">{{$t('copyTrading.copyText3')}}</a>
                        <a :href="static_vars.domainURL+'register/'" class="button bg-white zulu_btn rounded border-button">{{$t('copyTrading.copyText4')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="blockElement space automated5">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-center mb-md-5 pb-4">{{$t('copyTrading.copyText51')}}</h2>
                </div>
                <div class="col-12 col-md-6 mb-4">
                    <div class="d-flex align-items-start">
                        <span class="automated me-3 flex-none"><v-lazy-image class="d-block" src="/assets/images/Simulation.svg" :alt="$t('copyTrading.copyText52')" :title="$t('copyTrading.copyText52')" /></span>
                        <div class="flex-none contentCalc">
                            <h4 class="bold">{{$t('copyTrading.copyText52')}}</h4>
                            <p>{{$t('copyTrading.copyText53')}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-4">
                    <div class="d-flex align-items-start">
                        <span class="automated me-3 flex-none"><v-lazy-image class="d-block" src="/assets/images/Automator-1.svg" :alt="$t('copyTrading.copyText54')" title="$t('copyTrading.copyText54')" /></span>
                        <div class="flex-none contentCalc">
                            <h4 class="bold">{{$t('copyTrading.copyText54')}}</h4>
                            <p>{{$t('copyTrading.copyText55')}} </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-4">
                    <div class="d-flex align-items-start">
                        <span class="automated me-3 flex-none">
                            <v-lazy-image class="d-block" src="/assets/images/ZuluGuard-1.svg" :alt="$t('copyTrading.copyText56')" :title="$t('copyTrading.copyText56')" />
                        </span>
                        <div class="flex-none contentCalc">
                            <h4 class="bold">{{$t('copyTrading.copyText56')}}</h4>
                            <p>{{$t('copyTrading.copyText57')}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-4">
                    <div class="d-flex align-items-start">
                        <span class="automated me-3 flex-none">
                            <v-lazy-image class="d-block" src="/assets/images/Trading-chart.svg" :alt="$t('copyTrading.copyText57')" :title="$t('copyTrading.copyText57')" />
                        </span>
                        <div class="flex-none contentCalc">
                            <h4 class="bold">{{$t('copyTrading.copyText57')}}</h4>
                            <p>{{$t('copyTrading.copyText58')}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="dualButton d-flex align-items-center justify-content-center mt-4">
                        <a :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn large me-3 px-md-4">{{$t('copyTrading.copyText3')}}</a>
                        <a :href="static_vars.domainURL+'register/'" class="button bg-white zulu_btn rounded border-button">{{$t('copyTrading.copyText4')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="blockElement space faqs automated6 bg-white">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="mb-md-4 pb-4">{{$t('copyTrading.copyText59')}}</h2>
                </div>
                <div class="col-12">
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    {{$t('copyTrading.copyText60')}}
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    {{$t('copyTrading.copyText61')}}
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    {{$t('copyTrading.copyText62')}}
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    {{$t('copyTrading.copyText63')}}
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    {{$t('copyTrading.copyText64')}}
                                </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    {{$t('copyTrading.copyText65')}}
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    {{$t('copyTrading.copyText66')}}
                                </button>
                            </h2>
                            <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    {{$t('copyTrading.copyText67')}}
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingfive">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                                    {{$t('copyTrading.copyText68')}}
                                </button>
                            </h2>
                            <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    {{$t('copyTrading.copyText69')}}
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingsix">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-headingsix" aria-expanded="false" aria-controls="flush-collapsefive">
                                    {{$t('copyTrading.copyText70')}}
                                </button>
                            </h2>
                            <div id="flush-headingsix" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    {{$t('copyTrading.copyText71')}}
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingseven">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-headingseven" aria-expanded="false" aria-controls="flush-collapsefive">
                                    {{$t('copyTrading.copyText72')}}
                                </button>
                            </h2>
                            <div id="flush-headingseven" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    {{$t('copyTrading.copyText51')}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

</template>